import React from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const PhotoGallery = ({ Photos }) => {
  return (
    <SlideshowLightbox className="container grid grid-cols-2 sm:grid-cols-3 gap-6 mx-auto">
      {Photos?.map((photo, ind) => {
        return (
          <img
            key={ind}
            className="w-full rounded-[8px] border-[2px] sm:border-[4px] border-[#e3204b]"
            src={photo?.imgSrc}
            alt={photo?.altTitle}
          />
        );
      })}
    </SlideshowLightbox>
  );
};

export default PhotoGallery;
