import React from 'react'
import Banner from '../components/Banner/Banner'
import WhatWeDo from '../components/Home/WhatWeDo/WhatWeDo';
import OurPortfolio from '../components/Home/OurPortfolio/OurPortfolio';
import OurClients from '../components/Home/OurClients/OurClients';
import CtaOne from '../components/Home/CtaOne/CtaOne';
import ClientsFeedback from '../components/Home/ClientsFeedback/ClientsFeedback';
import Reviews from '../components/Home/Reviews/Reviews';


const Home = ({sectionRefs}) => {
  return (
    <>
      <Banner sectionRefs={sectionRefs} />
      <WhatWeDo sectionRefs={sectionRefs} />
      <OurPortfolio sectionRefs={sectionRefs} />
      <CtaOne />
      <OurClients sectionRefs={sectionRefs} />
      <ClientsFeedback />
      <Reviews />
    </>
  )
};

export default Home;
