import React from 'react'
import InnerPageBanner from '../components/Banner/InnerPageBanner'
import BannerBG from "../assests/img/creattive-dots-banner.jpeg";
import EventsPhotos, { ProductPhotos, PortraitPhotos } from "../components/Photography/PhotoGraphyData";
import ShootsSection from '../components/Photography/ShootsSection';


const Photography = () => {
  return (
    <>
      <InnerPageBanner BannerBG={BannerBG} Title='Photography' />
      <ShootsSection
        bgStyle="#fff"
        Title="Events Shoots"  
        Description="Showcase your brand with stunning photography and videography, creating visuals that tell your brand’s story beautifully."  
        Photos={EventsPhotos}
      />
      <ShootsSection
        bgStyle="#eeeeee"
        Title="Product Shoots"  
        Description="Showcase your brand with stunning photography and videography, creating visuals that tell your brand’s story beautifully."  
        Photos={ProductPhotos}
      />
      <ShootsSection
        bgStyle="#fff"
        Title="Portrait Shoots"  
        Description="Showcase your brand with stunning photography and videography, creating visuals that tell your brand’s story beautifully."  
        Photos={PortraitPhotos}
      />
      <ShootsSection
        bgStyle="#eeeeee"
        Title="Podcast"  
        Description="Showcase your brand with stunning photography and videography, creating visuals that tell your brand’s story beautifully."  
      />
    </>
  )
}

export default Photography;