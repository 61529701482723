
import video from "../../../assests/img/With_text_DG_Website_video.mp4-vimeo-771916988-dash-google_mediacdn_sep-video-5d4493e8.mp4";
import VideoModal from "../../Modals/VideoModal";
import "./ClientsFeedback.css";

const ClientsFeedback = () => {
  return (
    <section className="bg-[#000] px-4 md:px-0 py-14 md:py-20 w-full">
      <div className="gravity-container flex flex-col gap-10 sm:gap-14">
        <h2 className="text-[32px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center">
            HERE IS WHAT OUR CLIENTS SAY...
        </h2>
        <div className="w-full max-w-[1024] flex flex-wrap justify-between items-center gap-8 sm:gap-4">
            <div className="w-full sm:w-[48%]">
                {/* <video src={video} className="w-full h-full rounded-xl border-[3px] border-[#C61E46] " autoPlay muted loop type="video/mp4"></video> */}
                <div className="w-full h-[220px] md:h-[300px] flex justify-center items-center rounded-xl border-[3px] border-[#c784f7] bg-[#fff] cursor-pointer avatar-bg1" > 
                  <VideoModal videoUrl={video} />
                </div>
                <h4 className="mt-6 text-[24px] font-montserrat font-bold leading-[30px] text-[#fff] uppercase text-center"> 
                    We Shoot Custom
                </h4>
                <h4 className="mt-1 text-[18px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center"> 
                  Brand Vidoes Products Shoots
                </h4>
            </div>
            <div className="w-full sm:w-[48%]">
                <div className="w-full h-[220px] md:h-[300px] flex justify-center items-center rounded-xl border-[3px] border-[#c784f7] bg-[#fff] cursor-pointer avatar-bg2" > 
                  <VideoModal videoUrl={video}  />
                </div>
                {/* <video src={video} className="w-full h-full rounded-xl border-[3px] border-[#C61E46]" autoPlay muted loop type="video/mp4"></video> */}
                <h4 className="mt-6 text-[24px] font-montserrat font-bold leading-[30px] text-[#fff] uppercase text-center"> 
                    We Edit Like
                </h4>
                <h4 className="mt-1 text-[18px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center"> 
                    Best Marketing Commercial
                </h4>
            </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsFeedback;
