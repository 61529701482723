import React from 'react'
import PhotoGallery from '../PhotoGallery/PhotoGallery'

const ShootsSection = ({bgStyle, Title, Description, Photos}) => {
  return (
    <section className='py-16 px-4 sm:px-2 md:px-0' style={{backgroundColor: bgStyle}}>
      <div className='gravity-container'>
        <h2 className="mb-4 text-[32px] font-montserrat font-bold leading-[30px] text-[#C61E46] uppercase text-center">
            {Title}
        </h2>
        <p className="mb-12 mx-auto w-full sm:w-[70%] font-montserrat font-medium text-[16px] text-center">
            {Description}
        </p>
        {
          Photos ? 
          <PhotoGallery Photos={Photos} /> :
          <h2 className="mb-4 text-[32px] font-montserrat font-bold leading-[60px] text-[#000] uppercase text-center">
            Comming Soon
          </h2>
        }
        
      </div>
    </section>
  )
}

export default ShootsSection