import React from "react";
import "./Banner.css";
// import video from "../../assests/video/bnr-bg.mp4";
import video from "../../assests/video/gravity-digital.mp4";


const Banner = ({sectionRefs}) => {
  const whatsappUrl = `https://wa.me/${'+59123456789'}?text=${encodeURIComponent('Hey, can you audit my social media?')}`;
  return (
    <>
    <div className="relative" id="home" ref={sectionRefs.home}>
      <div className="hidden text-center lg:block absolute px-4 pt-6 pb-10 top-[30%] left-10 lg:w-[47%] xl:w-[41%] text-[#fff] z-[9] rounded-xl bg-[#c784f76b] banner-area">
        <h1 className="flex flex-col items-center gap-3 mb-6"> 
          <span className="text-[22px] font-montserrat font-bold leading-[30px] text-[#6dfe79] uppercase"> 
            SOCIAL MEDIA MANAGEMENT SERVICE
          </span>
          <span className="text-[34px] font-montserrat font-bold leading-[40px] w-full lg:w-[90%] "> 
            Connect With Your Audience, Build A Brand And Increase Sales. 
          </span>
        </h1>
        <a 
          href={whatsappUrl} target="_blank" 
          className="!text-[18px] font-montserrat !font-medium text-[#fff] hover:bg-[#6dfe79] bg-[#c784f7] px-8 py-3 rounded-lg "> 
          GET FREE SOCIAL MEDIA AUDIT TODAY! 
        </a>
      </div>
      <div className="bg-video w-full h-full sm:min-h-[500px]">
        <video src={video} className="" autoPlay muted loop type="video/mp4"></video>
      </div>
    </div>
    </>
  );
};

export default Banner;
