import ReviewImg1 from "../../../assests/img/pleasant-looking-serious-blonde-woman.jpeg"
import ReviewImg2 from "../../../assests/img/caucasian-handsome-man.jpeg"
import ReviewImg3 from "../../../assests/img/tender-feminine-woman.jpeg"

const ReviewData = [
    {
        imgSrc: ReviewImg1,
        client_name: 'Dana Smith',
        location: 'New York, USA',
        client_review: "From the very start, Creative Dots Media Agency understood our vision and executed it flawlessly. Their ability to craft compelling campaigns and deliver measurable results has been instrumental in our success. The team's dedication and expertise are truly commendable."
    },
    {
        imgSrc: ReviewImg2,
        client_name: 'Jhon Doe',
        location: 'Chicago, IL',
        client_review: "Their data-driven approach and creative content have significantly boosted our traffic and customer engagement. We highly recommend their services to any business looking to enhance their marketing efforts."
    },
    {
        imgSrc: ReviewImg3,
        client_name: 'Kelly Wilson',
        location: 'Miami, FL',
        client_review: "Working with Creative Dots Media Agency has been a game-changer for our brand. Their innovative marketing strategies and meticulous attention to detail have significantly boosted our online presence."
    },
]   
export default ReviewData;