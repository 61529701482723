import EventsPhoto1 from "../../assests/img/creativedots-media-agency-auto-polis1.png";
import EventsPhoto2 from "../../assests/img/creativedots-media-agency-auto-polis5.png";
import EventsPhoto3 from "../../assests/img/creativedots-media-agency-auto-polis2.png";
import EventsPhoto4 from "../../assests/img/creativedots-media-agency-auto-polis3.png";
import EventsPhoto5 from "../../assests/img/creativedots-media-agency-auto-polis2.png";
import EventsPhoto6 from "../../assests/img/creativedots-media-agency-auto-polis5.png";

export const EventsPhotos = [
  {
    imgSrc: EventsPhoto1,
    altText: "Events Shoot Photo",
  },
  {
    imgSrc: EventsPhoto2,
    altText: "Events Shoot Photo",
  },
  {
    imgSrc: EventsPhoto3,
    altText: "Events Shoot Photo",
  },
  {
    imgSrc: EventsPhoto4,
    altText: "Events Shoot Photo",
  },
  {
    imgSrc: EventsPhoto5,
    altText: "Events Shoot Photo",
  },
  {
    imgSrc: EventsPhoto6,
    altText: "Events Shoot Photo",
  },
];

export const ProductPhotos = [
  {
    imgSrc: EventsPhoto1,
    altText: "Product Shoot Photo",
  },
  {
    imgSrc: EventsPhoto2,
    altText: "Product Shoot Photo",
  },
  {
    imgSrc: EventsPhoto3,
    altText: "Product Shoot Photo",
  },
  {
    imgSrc: EventsPhoto4,
    altText: "Product Shoot Photo",
  },
  {
    imgSrc: EventsPhoto5,
    altText: "Product Shoot Photo",
  },
  {
    imgSrc: EventsPhoto6,
    altText: "Product Shoot Photo",
  },
];

export const PortraitPhotos = [
  {
    imgSrc: EventsPhoto1,
    altText: "Portrait Shoot Photo",
  },
  {
    imgSrc: EventsPhoto2,
    altText: "Portrait Shoot Photo",
  },
  {
    imgSrc: EventsPhoto3,
    altText: "Portrait Shoot Photo",
  },
  {
    imgSrc: EventsPhoto4,
    altText: "Portrait Shoot Photo",
  },
  {
    imgSrc: EventsPhoto5,
    altText: "Portrait Shoot Photo",
  },
  {
    imgSrc: EventsPhoto6,
    altText: "Portrait Shoot Photo",
  },
];

export default EventsPhotos;
