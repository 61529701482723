import list1_photo1 from '../../../assests/img/creativedots-media-agency-dogtrainer.png';
import list1_photo2 from '../../../assests/img/creativedots-media-agency-dogtrainer1.png';
import list1_photo3 from '../../../assests/img/creativedots-media-agency-dogtrainer2.png';
import list1_photo4 from '../../../assests/img/creativedots-media-agency-dogtrainer3.png';
import list1_photo5 from '../../../assests/img/creativedots-media-agency-dogtrainer4.png';
import list1_photo6 from '../../../assests/img/creativedots-media-agency-dogtrainer5.png';

import list2_photo1 from '../../../assests/img/creativedots-media-agency-auto-polis1.png';
import list2_photo2 from '../../../assests/img/creativedots-media-agency-auto-polis2.png';
import list2_photo3 from '../../../assests/img/creativedots-media-agency-auto-polis3.png';
import list2_photo4 from '../../../assests/img/creativedots-media-agency-auto-polis4.png';
import list2_photo5 from '../../../assests/img/creativedots-media-agency-auto-polis12.png';
import list2_photo6 from '../../../assests/img/creativedots-media-agency-auto-polis5.png';


const PortfolioList = [
    {
        id: 1,
        imgSrc: list1_photo1,
    },
    {
        id: 2,
        imgSrc: list1_photo2,
    },
    {
        id: 3,
        imgSrc: list1_photo3,
    },
    {
        id: 4,
        imgSrc: list1_photo4,
    },
    {
        id: 5,
        imgSrc: list1_photo5,
    },
    {
        id: 6,
        imgSrc: list1_photo6,
    },
];
export const PortfolioList2 = [
    {
        id: 1,
        imgSrc: list2_photo1,
    },
    {
        id: 2,
        imgSrc: list2_photo2,
    },
    {
        id: 3,
        imgSrc: list2_photo3,
    },
    {
        id: 4,
        imgSrc: list2_photo4,
    },
    {
        id: 5,
        imgSrc: list2_photo5,
    },
    {
        id: 6,
        imgSrc: list2_photo6,
    },
];

export default PortfolioList;

