import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BlogsData from '../Blogs/BlogsData';
import InnerPageBanner from '../Banner/InnerPageBanner';
import BannerBG from "../../assests/img/creattive-dots-banner.jpeg";

const SingleBlogPage = () => {
    const params = useParams();
    const [blog, setBlog] = useState({});

    useEffect(()=>{
        if(params) {
            const BlogObj = BlogsData?.find((blog) => blog?.Title === params?.title);
            setBlog(BlogObj)
        }
    },[params])
  return (
    <>
        <InnerPageBanner BannerBG={BannerBG} Title={blog?.Title} />
    </>
  )
}

export default SingleBlogPage