import React, {useState, useEffect} from "react";
import Phonehoto1 from "../../../assests/img/creative-mobile01.png";
// import Phonehoto1 from "../../../assests/img/gravity_phone.webp";
import Phonehoto2 from "../../../assests/img/creative-mobile02.png";
// import Phonehoto2 from "../../../assests/img/phone_photo_2.webp";
import PortfolioList, { PortfolioList2 } from "./PortfolioList";


const OurPortfolio = ({sectionRefs}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % PortfolioList.length);
      }, 5000);
  
      return () => clearInterval(intervalId);
    }, []);

  return (
    <>
      <section id="Portfolio" className=" bg-[#fff] px-4 md:px-0 py-14 md:py-20 w-full" ref={sectionRefs.Portfolio}>
        <div className="gravity-container flex flex-col gap-20">
          <h2 className="text-[32px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center">
            Our Portfolio
          </h2>
          <div className="w-full flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-1/3">
              <div className="w-full h-auto">
                <img
                  src={Phonehoto1}
                  alt="phone_photo"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className="w-full sm:w-2/3 flex justify-center items-center rounded-[18px]">
              <div className="grid gap-2 sm:gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 grid-rows-2">
                {PortfolioList.map((item, ind) => {
                  return (
                    <div className="h-inherit sm:h-[160px] md:h-[160px] lg:h-[210px] w-inherit sm:w-[160px] md:w-[180px] lg:w-[210px]" key={ind}>
                        <img
                            src={PortfolioList[(currentImageIndex + ind) % PortfolioList.length].imgSrc}
                            alt={`gallery_photo_${item.id}`}
                            className="w-full h-full"
                        />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" bg-[#fbfbfb] px-4 md:px-0 py-14 md:py-20 w-full">
        <div className="gravity-container flex flex-col gap-20">
          <div className="w-full flex flex-col-reverse sm:flex-row gap-4">
            <div className="w-full sm:w-2/3 flex justify-center items-center rounded-[18px]">
              <div className="grid gap-2 sm:gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 grid-rows-2">
                {PortfolioList2.map((item, ind) => {
                  return (
                    <div className="h-inherit sm:h-[160px] md:h-[160px] lg:h-[210px] w-inherit sm:w-[160px] md:w-[180px] lg:w-[210px]" key={ind}>
                      <img
                        src={PortfolioList2[(currentImageIndex + ind) % PortfolioList2.length].imgSrc}
                        alt={`gallery_photo_${item.id}`}
                        className="w-full h-full"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full sm:w-1/3">
              <div className="w-full h-auto">
                <img
                  src={Phonehoto2}
                  alt="phone_photo"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPortfolio;
