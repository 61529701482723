import React from 'react';
import { Carousel } from 'antd';
import ReviewData from './ReviewData';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; 

const Reviews = () => {

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const Arrow = ({ IconComponent, style, className, onClick }) => (
    <IconComponent style={style} className={className} onClick={onClick} />
  );

  return (
    <section id="Reviews" className=" bg-[#fbfbfb] px-4 md:px-0 py-14 md:py-20 w-full z-[99]">
      <div className="gravity-container flex flex-col gap-10 sm:gap-14">
        <h2 className="text-[32px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center">
          Our Testimonials
        </h2>
        <div className="w-full sm:w-[60%] mx-auto">
          <Carousel afterChange={onChange} 
            arrows
            autoplay
            prevArrow={<Arrow IconComponent={LeftOutlined} />}
            nextArrow={<Arrow IconComponent={RightOutlined} />}
          >
            {
              ReviewData?.map((item, ind) => {
                return(
                  <div key={ind} className='bg-[#eeeeee] mx-auto shadow-lg h-[480px] sm:h-[400px] w-full border-[6px] border-[#fff] rounded-[6px]'>
                    <div className='carousel-media flex justify-center mt-3 mb-5'>
                      <img src={item?.imgSrc} alt='creative-dots-client-review' className='w-[90px] sm:w-[120px] h-[90px] sm:h-[120px] rounded-[50%] border-[6px] border-[#fff]' />
                    </div>
                    <div className='carousel-body text-center px-6 '>
                      <h3 className='island-moments text-[42px] sm:text-[56px] leading-[50px]'> {item?.client_name} </h3>
                      <h4 className='font-montserrat uppercase mb-6 font-[500]'> {item?.location} </h4>
                      <p className='font-montserrat text-[16px]'> {item?.client_review} </p>
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Reviews;

