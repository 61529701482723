import React, { useState } from 'react';
import Modal from 'react-modal';
import { PlayCircleFilled, CloseCircleOutlined } from '@ant-design/icons';

const VideoModal = ({ videoUrl }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
  };

  return (
    <>
      {/* <img src={avatarUrl} alt="missing" className="w-full h-full cursor-pointer border-2 border-[#f59223] rounded-[6px]" onClick={openModal} /> */}
      <PlayCircleFilled className="text-[48px] text-[#c784f7]" onClick={openModal} /> 
       <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='absolute right-2 top-2 z-[999] bg-[#c784f7] p-2 shadow-lg rounded-[50%] flex justify-center items-center'>
            <CloseCircleOutlined style={{ fontSize: '24px', color: '#fff'}} />
        </button>
        <video src={videoUrl} className="w-full md:w-[600px] h-full rounded-xl border-[3px] border-[#c784f7]" autoPlay muted loop type="video/mp4"></video>
      </Modal>
    </>
  );
};

export default VideoModal;
