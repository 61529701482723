import React from "react";

const InnerPageBanner = ({ BannerBG, Title }) => {
  const bannerStyle = {
    backgroundImage: `url(${BannerBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={bannerStyle} className="h-[200px] sm:h-[280px] w-full flex justify-center items-center">
      <h2 className="text-[28px] sm:text-[36px] font-[700] text-[#fff] font-poppins">
        {Title}
      </h2>
    </div>
  );
};

export default InnerPageBanner;
