import React from 'react'
import { WhatsAppOutlined } from '@ant-design/icons';

const CtaOne = () => {
  const whatsappUrl = `https://wa.me/${'+59123456789'}?text=${encodeURIComponent('Hey, can you audit my social media?')}`;

  return (
    <section className="bg-[#c784f7] px-4 md:px-2 py-14 md:py-16 w-full">
      <div className="gravity-container flex flex-col sm:flex-row gap-10 sm:gap-20 justify-center items-center">
        <h2 className="w-full sm:w-1/2 text-[24px] sm:text-[26px] font-montserrat font-bold leading-[36px] text-[#fff] uppercase text-center sm:text-left">
            Request FREE <br />
            Social Media Audit
        </h2>
        <div className="w-full sm:w-1/2 flex justify-center sm:justify-end items-center">
        <a href={whatsappUrl} target='_blank' className="flex items-center gap-2 text-[20px] font-montserrat font-medium text-[#fff] hover:text-[#000] hover:bg-[#6dfe79] bg-[#000] btn-slide border-2 border-[#c784f7] px-8 py-4 rounded-lg gravity-transition">
           <WhatsAppOutlined style={{ color: '#23cc63', fontSize: '22px'}} /> Let's Talk
        </a>
        </div>
      </div>
    </section>
  )
}

export default CtaOne