import React from "react";

const UpArrow = () => {
  return (
    <svg
      width="50px"
      height="50px"

      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariaHidden="true"
      role="img"
      className="iconify iconify--emojione-monotone w-[35px] h-[35px] sm:w-[50px] sm:h-[50px]"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm5.143 28.305V49H26.857V30.305H16L32 15l16 15.305H37.143z"
        fill="#c784f7"
      ></path>
    </svg>
  );
};

export default UpArrow;
