import QuotesIcon from "../../../assests/Svg/QuotesIcon";
import {
    LinkedinOutlined,
    FacebookFilled,
    DiscordOutlined,
  } from "@ant-design/icons";
  import SnapChat from "../../../assests/Svg/SnapChat";
  import Telegram from "../../../assests/Svg/Telegram";
  import Instagram from "../../../assests/Svg/Instagram";
  import Tiktok from "../../../assests/Svg/Tiktok";
  import Raddit from "../../../assests/Svg/Raddit";
  import TwitterX from "../../../assests/Svg/TwitterX";


const WhatWeDoData = [
    {
        Title: "SMM/SEO",
        Description: "Enhance your online presence with targeted social media management and SEO strategies to drive organic traffic and engagement.",
        Icon: <QuotesIcon />
    },
    {
        Title: "Digital Marketing",
        Description: "Boost your brand with our comprehensive digital marketing solutions, from strategy to execution, ensuring you stand out online.",
        Icon: <QuotesIcon />
    },
    {
        Title: "Ad Campaigns/Lead Generation",
        Description: "Increase quality leads and conversions with our data-driven ad campaigns, delivering measurable results and a higher ROI.",
        Icon: <QuotesIcon />
    },
    {
        Title: "Personal Branding/Brand Building",
        Description: "Build a strong, recognizable identity with our personalized branding services, connecting you with your target audience effectively.",
        Icon: <QuotesIcon />
    },
    {
        Title: "Content Creation",
        Description: "Engage your audience with high-quality, compelling content. Deliver engaging, high-quality content that resonates with your audience and aligns with your brand's message.",
        Icon: <QuotesIcon />
    },
    {
        Title: "Photography/Videography",
        Description: "Showcase your brand with stunning photography and videography, creating visuals that tell your brand’s story beautifully.",
        Icon: <QuotesIcon />
    },
];
export default WhatWeDoData;

export const SocialData = [
    {
        Link: '#',
        Icon: <FacebookFilled style={{ color: "#fff", fontSize: "28px" }} className="icon-filled" />
    },
    {
        Link: '#',
        Icon: <Instagram styleClass="icon-filled" />
    },
    {
        Link: '#',
        Icon: <SnapChat styleClass="icon-filled" />
    },
    {
        Link: '#',
        Icon: <Tiktok styleClass="icon-filled" />
    },
    {
        Link: '#',
        Icon: <Telegram styleClass="icon-filled" />
    },
    {
        Link: '#',
        Icon: <DiscordOutlined style={{ color: "#fff", fontSize: "30px" }} className="icon-filled" />
    },
    {
        Link: '#',
        Icon: <TwitterX styleClass="icon-filled" />
    },
    {
        Link: '#',
        Icon: <LinkedinOutlined style={{ color: "#fff", fontSize: "28px" }} className="icon-filled" />
    },
    {
        Link: '#',
        Icon: <Raddit styleClass="icon-filled" />
    }
];