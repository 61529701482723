import React from "react";
import twitter from "../../assests/Svg/twiter-footer.svg"
import facebook from "../../assests/Svg/facebook-footer.svg"
import insta from "../../assests/Svg/instagram-footer.svg"
import youtube from "../../assests/Svg/youtube-footer.svg"
import linkedin from "../../assests/Svg/linkedin-footer.svg"
import "./Footer.css";
import GravityLogo from "../../assests/img/SSS-png.png";

const Footer = () => {
  return (
    <footer className="w-full bg-[#000]">
       <div className="footer-wrap w-[100%] flex flex-col md:flex-row items-stretch">
          <div className="footer-info-sec w-full md:w-[42%] lg:w-[50%] bg-[#c784f7] pt-[20%] sm:pt-[16%] md:pt-[15%] px-4 md:px-6 md:px-[2%] lg:px-[10%] pb-[50px] sm:pb-[5%] text-[#fff]">
              <h2 className="text-[26px] font-montserat font-[300] flex flex-col leading-[29px]">
                let's build
                <span className="font-[600]">something awesome</span>
              </h2>
              <p className="text-[15px] font-montserat font-[400] my-[20px]">Reach out to our team and we'll get back to you within a day.</p>
              <div className="location-sec flex w-[100%] gap-[20px]">
                <div className="w-[15%]"><hr className="break-footer w-[100%] border-[1px] border-[#fff] rounded-lg mt-[10px]"/></div>
                <div className="footer-location w-[85%]">
                  <div className="text-[14px] font-[400] font-montserat mb-1 footer__collapsable w-max cursor-pointer"><a className="w-max">info@creativesdots.com</a></div>
                  <div className="text-[14px] font-[400] font-montserat mb-1 footer__collapsable w-max cursor-pointer"><a className="w-max">+971 4 242 1375</a></div>
                  <div className="text-[14px] font-[400] font-montserat mb-1 footer__collapsable w-max cursor-pointer"><a className="w-max">+971 4 242 1375</a></div>
                  <div className="social-links flex gap-[5px] items-center mt-[20px]">
                    <div className="icon-slide p-2"> 
                      <img src={twitter} alt="" srcSet="" className="w-[1.2em] h-[1.2em] cursor-pointer"/> 
                    </div>
                    <div className="icon-slide p-2">
                      <img src={facebook} alt="" srcSet="" className="w-[1.2em] h-[1.2em] cursor-pointer"/>
                    </div>
                    <div className="icon-slide p-2">
                      <img src={insta} alt="" srcSet="" className="w-[1.2em] h-[1.2em] cursor-pointer"/>
                    </div>
                    <div className="icon-slide p-2">
                      <img src={youtube} alt="" srcSet="" className="w-[1.2em] h-[1.2em] cursor-pointer"/>
                    </div>
                    <div className="icon-slide p-2">
                      <img src={linkedin} alt="" srcSet="" className="w-[1.2em] h-[1.2em] cursor-pointer"/>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <h3 className="outline-heading text-[#6dfe79] absolute mt-[5%] md:mt-[4%] ml-[15px] md:[60px] lg:ml-[125px] text-center text-[28px] sm:text-[2em] md:text-[3em] lg:text-[5em]"> 
            Build your brand
          </h3>
          <div className="footer-link-sec w-full md:w-[58%] lg:w-[50%] bg-[#363636] pt-[40px] md:pt-[18%] px-4 md:pl-[3%] lg:px-[5%] pb-[45px] sm:pb-[5%] text-[#fff] flex flex-col gap-4 items-start">
              <div> 
                <img
                    src={GravityLogo}
                    alt="Creative.dot"
                    className="rounded-full w-[120px]"
                  />
                  {/* <span className='text-[20px] text-[#fff] font-medium'> Creative Dots </span> */}
              </div>
              <h2 className="text-[22px] font-montserat font-[300] flex flex-col leading-[29px]">
                Rewamp your brand, reach more audience with influencer Marketing and community management.
              </h2>

              <p className="text-[15px] font-montserat font-regular">
                We also have experts in web3, crypto market.
              </p>
          </div>
       </div>
    </footer>
  );
};

export default Footer;
