import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import GravityLogo from "../../assests/img/S-png.png"
import { CloseOutlined, MenuOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';

const Navbar = ({activeSection}) => {
  const activeStyle = "text-[#c784f7] font-montserat text-[14px] font-medium";
  const nonActiveStyle = "font-[500] text-[#fff] font-montserat text-[14px] font-medium";
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);


  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleToggleMenu =()=>{
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const stickyPoint = 1;
      if (currentScrollY > stickyPoint) {
        setSticky(true);
      } else {
        setSticky(false);
      }
      setPrevScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  return (
    <div className={
      isSticky ? "flex justify-between fixed w-full gap-[100px] sm:gap-[150px] max-w-[1280px] bg-[#0F0C0F] z-[100]"
      : "flex gap-[100px] sm:gap-[150px] w-[100%] static bg-[#000000] z-[100]"}>
      <div className="w-unset sm:w-[20%] bg-[#000000] text-[#6dfe79] p-4 cursor-pointer">
        <Link to='/'>
          <img
            src={GravityLogo}
            alt="Creative.dot"
            className="rounded-full w-[80px]"
          />
          {/* <span className='text-[20px] font-medium'> Creative Dots </span> */}
        </Link>
      </div>
      <div className="flex gap-0 md:gap-[30px] w-[40%] sm:w-[80%] items-center justify-end pr-4 md:pr-0">
        <button
          className={`text-[24px] md:hidden -mt-2 ${isSticky ? 'text-[#fff]' : 'text-[#867767]'}`}
          onClick={handleToggleMenu}
        >
          {menuOpen ? <CloseOutlined style={ isSticky ? {color: '#fff'} : {color: '#fff'}} />  : 
            <MenuOutlined style={ isSticky ? {color: '#fff'} : {color: '#fff'}} />}
        </button>
        <div
        className={`md:flex md:items-center md:gap-[30px] md:top-[100px] ${
          menuOpen ? 'flex flex-col mt-6 md:top-[100px] absolute top-[32px] left-[0px] bg-[#000] w-[100%] py-[10px] px-[20px] gap-[14px] z-[100]' : 'hidden'
        }`}
      >
          <a href="/">
            <div className="flex hover:text-[#6dfe79] bg-fixed transition duration-300 ease-in-out rounded-lg">
              <li className={`flex ${activeSection === "home" ? activeStyle : nonActiveStyle}`} onClick={closeMenu}>
                Home 
              </li>
            </div>
          </a>
          <a href="/#WhatWeDo">
            <div className="flex hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className={`flex ${activeSection === "WhatWeDo" ? activeStyle : nonActiveStyle}`}>
                About
              </li>
            </div>
          </a>
          <a href="/#WhatWeDo">
            <div className="flex  hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className={`flex ${activeSection === "WhatWeDo" ? activeStyle : nonActiveStyle}`}>
                Services
              </li>
            </div>
          </a>
          <a href="/#Portfolio">
            <div className="flex  hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className={`flex ${activeSection === "Portfolio" ? activeStyle : nonActiveStyle}`}>
                Portfolio
              </li>
            </div>
          </a>
          <a href="/#OurClients">
            <div className="flex  hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className={`flex ${activeSection === "OurClients" ? activeStyle : nonActiveStyle}`}>
                Clients 
              </li>
            </div>
          </a>
          <Link to="/photography">
            <div className="flex  hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className='flex font-[500] text-[#fff] font-montserat text-[14px] font-medium'>
                Photography 
              </li>
            </div>
          </Link>
          <Link to="/blogs">
            <div className="flex hover:text-[#6dfe79] bg-fixed  transition duration-300 ease-in-out rounded-lg">
              <li className='flex font-[500] text-[#fff] font-montserat text-[14px] font-medium'>
                Blogs 
              </li>
            </div>
          </Link>
        </div>
        <div className="flex bg-[#fff] h-full">
          {/* <button className="font-montserat font-[700] text-[#E3204B] text-[0.75em] captilize px-3 hidden lg:block">Speak to an Expert</button> */}
          <button className="font-tajawal text-[0.75em] bg-[#c784f7] icon-slide px-3 hidden lg:block" >
            <WhatsAppOutlined style={{color: '#fff', fontSize: '22px'}} />
          </button>
          <button className="font-tajawal text-[0.75em] bg-[#c784f7] icon-slide px-3 hidden lg:block" >
             <PhoneOutlined rotate='90' style={{color: '#fff', fontSize: '22px'}} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar
