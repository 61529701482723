import React, { useState, useEffect, useRef } from 'react';
import {Routes, Route} from "react-router-dom"
import Home from './Pages/Home';
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import "./index.css"
import UpArrow from './assests/Svg/UpArrow';
import Blogs from './Pages/Blogs';
import Photography from './Pages/Photography';
import SingleBlogPage from './components/SingleBlogPage/SingleBlogPage';

const App = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const [activeSection, setActiveSection] = useState(null);

  const sectionRefs = {
    home: useRef(null),
    WhatWeDo: useRef(null),
    Portfolio: useRef(null),
    OurClients: useRef(null)
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.7 // Adjust the threshold as needed
      }
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs]);

  return (
    <div className='w-full max-w-[1280px] m-auto p-auto'>
      <Navbar activeSection={activeSection} />
        <Routes>
          <Route path="/" element={<Home sectionRefs={sectionRefs} />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:title" element={<SingleBlogPage />} />
        </Routes>
      <Footer/>

      {isVisible && (
        <button className="scroll-to-top-btn" onClick={scrollToTop}>
          <UpArrow />
        </button>
      )}
    </div>
  )
}

export default App;
