import React from "react";
import {
  WhatsAppOutlined
} from "@ant-design/icons";
import '../Home.css'

import WhatWeDoData, { SocialData } from "./WhatWeDoData";

const WhatWeDo = ({sectionRefs}) => {
  const whatsappUrl = `https://wa.me/${'+59123456789'}?text=${encodeURIComponent('Hey, can you audit my social media?')}`;

  return (
    <section
      ref={sectionRefs.WhatWeDo}
      id="WhatWeDo"
      className="bg-[#000] px-4 md:px-2 py-14 md:py-20 w-full"
    >
      <div className="gravity-container flex flex-col gap-20">
        <h2 className="text-[32px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center">
          What We Do
        </h2>
        <div className="flex flex-col sm:flex-row flex-wrap gap-4">
          {
            WhatWeDoData?.map((Service, ind) => {
              return(
                <div
                  key={ind}
                  className=" mx-auto flex flex-col items-center p-6 gap-8 gravity-transition w-full sm:w-[32%] serv-card-left waht-we-do-service-wrap"
                >
                  <div className="flex flex-col items-center gap-4">
                    <h3 className="text-[22px] font-semibold font-montserrat text-center">
                      {Service?.Title}
                    </h3>
                    <p className="font-montserrat font-medium text-[16px] text-center">
                      {Service?.Description}
                    </p>
                  </div>
                  <div className="quote-icon text-[24px] font-bold">
                      {Service?.Icon}
                  </div>
                </div>
              )
            }) 
          }
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-wrap justify-center items-center w-[82%] sm:w-max gap-2 p-2">
            {
              SocialData?.map((item, ind) => {
                return(
                  <a key={ind} href={item?.Link} className="font-tajawal text-[0.75em] icon-slide icon-fade-left p-2">
                    {item?.Icon}
                  </a>
                )
              })
            }
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-0 md:px-4">
          <h2 className="w-full sm:w-1/2 text-[24px] sm:text-[26px] font-montserrat font-bold leading-[36px] text-[#fff] uppercase text-center sm:text-left">
            We Custom Build Packages To Achieve Your Objectives
          </h2>
          <div className="w-full sm:w-1/2 flex justify-center sm:justify-end">
            <a href={whatsappUrl} target="_blank" className="flex items-center gap-2 text-[20px] font-montserrat font-medium text-[#fff] btn-slide bg-[#c784f7] hover:bg-[#fff] border-2 border-[#c784f7] px-8 py-4 mt-5 rounded-lg gravity-transition">
              <WhatsAppOutlined style={{ color: '#23cc63', fontSize: '22px'}} />Let's Talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
