import React from "react";
// import YalaMarket from "../../../assests/Svg/YalaMarket";
// import Cafo from "../../../assests/Svg/Cafo";
// import Baso from "../../../assests/Svg/Baso";
// import GorgWear from "../../../assests/Svg/GorgWear";

// grid gap-2 sm:gap-4 grid-cols-2 sm:grid-cols-4 grid-rows-1

const OurClients = ({sectionRefs}) => {
  return (
    <section id="OurClients" className=" bg-[#fff] px-4 md:px-0 py-14 md:py-20 w-full" ref={sectionRefs.OurClients}>
      <div className="gravity-container flex flex-col gap-10 sm:gap-14">
        <h2 className="text-[32px] font-montserrat font-bold leading-[30px] text-[#c784f7] uppercase text-center">
          We Build Brand Awareness
        </h2>
        <h2 class="text-[16px] font-montserrat font-bold leading-[30px] text-[#101010] uppercase text-center w-full sm:w-[80%] md:w-[65%] mx-auto">
          Empowering brands through tailored social media strategies for amplified online presence and engagement
        </h2>
        {/* <div className="w-full flex flex-wrap justify-between items-center gap-6">
            <div className="w-[42%] sm:w-[20%] h-full"> <YalaMarket className="w-full h-full " /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <Cafo className="w-full h-full" /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <Baso className="w-full h-full" /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <GorgWear className="w-full h-full" /> </div>
        </div>
        <div className="w-full flex flex-wrap justify-between items-center gap-6">
            <div className="w-[42%] sm:w-[20%] h-full"> <YalaMarket className="w-full h-full " /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <Cafo className="w-full h-full" /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <Baso className="w-full h-full" /> </div>
            <div className="w-[42%] sm:w-[20%] h-full"> <GorgWear className="w-full h-full" /> </div>
        </div> */}
      </div>
    </section>
  );
};

export default OurClients;
