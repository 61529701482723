import React from 'react'
import InnerPageBanner from '../components/Banner/InnerPageBanner'
import BannerBG from "../assests/img/creattive-dots-banner.jpeg";
import BlogsData from '../components/Blogs/BlogsData';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const navigate = useNavigate();

  const handleBlogView = (title) => {
    navigate(`/blog/${title}`)
  }
  return (
    <>
      <InnerPageBanner BannerBG={BannerBG} Title='Blogs' />
      <section className='w-full bg-[#eeeeee] py-10 sm:py-20 px-4 sm:px-2 md:px-0'>
        <div className='max-w-[1024px] w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mx-auto'>
            {
              BlogsData?.map((blog, ind) => {
                return(
                  <div key={ind} className='flex flex-col rounded-[16px] bg-[#fff]'>
                    <div className='blog-media-wrap h-[280px] w-[auto]'>
                      <img src={blog?.ImgSrc} alt={blog?.Title} className='h-full w-full' style={{borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}} />
                    </div>
                    <div className='blog-body-wrap p-6 sm:p-8 flex flex-col gap-3 justify-between h-full'>
                      <h2 className='text-[16px] font-[500] font-montserrat leading-[24px] text-[#C61E46] uppercase truncate'> {blog?.Category} </h2>
                      <h3 
                        className='text-[20px] sm:text-[24px] font-[500] font-montserrat leading-[26px] text-[#000]' 
                        style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
                      > 
                        {blog?.Title} 
                      </h3>
                      <p className='font-montserrat text-[16px] font-[500]'
                        style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
                      > 
                        {blog?.ShortText} 
                      </p>
                      <button 
                       onClick={()=> handleBlogView(blog?.Title)}
                       className='flex items-center gap-2 text-[20px] font-montserrat !font-[500] text-[#fff] bg-[#000] btn-slide border-2 border-[#C61E46] px-8 py-2 rounded-[12px] gravity-transition w-max'> 
                        Read More 
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
      </section>
    </>
  )
}

export default Blogs